<template>
  <div id="Photos" v-if="isThumbnail" class="home-panel photos-area">
    <p class="title title-right h1"><strong>P</strong>hotos</p>
    <el-row :gutter="16" class="content corner-radius-all" type="flex" justify="space-around">
      <!-- <Spin size="large" fix v-if="isLoading"></Spin> -->
      <el-col v-for="(photo, index) in photos" class="photo-cell"
        :xs="{span:24,offset:0}"
        :sm="{span:12,offset:0}"
        :md="{span:12,offset:0}"
        :lg="{span:12,offset:0}">
        <PhotoCell :photo="photo"/>
      </el-col>
    </el-row>
    <!--
    <router-link :to="this.router_link">
      <span class="read-more">rEAd mORe &gt;</span>
    </router-link>
    -->
  </div>
  <div v-else>
    <router-view/>
  </div>
</template>

<script>
import Axios from 'axios'
import PhotoCell from './photos/PhotoCell.vue'
import { ref, toRefs } from 'vue'
export default {
  name: 'Photos',
  components: {
    PhotoCell
  },
  props: {
    isThumbnail: Boolean
  },
  // data () {
  //   return {
  //     pageUrl: 'https://darkzero.me/blog/wp-json/wp/v2/photos?per_page=6',
  //     defaultImageUrl: require('../assets/project_default.jpg'),
  //     pageUrlBase: 'https://darkzero.me/blog/?page_id=',
  //     photos: [],
  //     isLoading : false
  //   }
  // },
  setup() {
    // values
    const isLoading = true
    const pageUrl = 'https://darkzero.me/blog/wp-json/wp/v2/photos?per_page=6'
    const photos = ref([])
    const pageUrlBase = 'https://darkzero.me/blog/?page_id='
    const defaultImageUrl = require('../assets/project_default.jpg')
    // functions

    // on created
    Axios.get(pageUrl).then(response => {
      console.log(response.data)
      let data = response.data
      data.forEach(item => {
        var photo = {
          'title' : item.title.rendered,
          'thumbnail' : item.photo_thumbnail,
          'description' : item.photo_description,
          'date' : item.photo_date,
          'image_url' : defaultImageUrl,
          'page_url': pageUrlBase+item.id
        }
        console.log(photo)
        if (item.photo_image != undefined) {
          photo['image'] = item.photo_image
        }
        photos.value.push(photo)
      })
      isLoading = false
    }).catch(err => {
      //errors.push(err)
    })
    // return
    return {
      photos
    }
  },
  created: function() {
    // this.isLoading = true
    // Axios
    //   .get(this.pageUrl)
    //   .then(response => {
    //     console.log(response.data)
    //     let data = response.data
    //     data.forEach(item => {
    //       var photo = {
    //         'title' : item.title.rendered,
    //         'thumbnail' : item.photo_thumbnail,
    //         'description' : item.photo_description,
    //         'date' : item.photo_date,
    //         'image_url' : this.defaultImageUrl,
    //         'page_url': this.pageUrlBase+item.id
    //       }
    //       console.log(photo)
    //       if (item.photo_image != undefined) {
    //         photo['image'] = item.photo_image
    //       }
    //       this.photos.push(photo)
    //     })
    //     this.isLoading = false
    //   })
    //   .catch(err => {
    //     this.errors.push(err)
    //   })
  },
  methods: {
    getPhotoImage: function(photo) {
      if (photo.thumbnail != undefined) {
        Axios.get(this.mediaUrl+photo.thumbnail)
        .then(response => {
          let data = response.data
          photo.image_url = data.media_details.sizes.medium.source_url
        })
        return
      }
    }
  }
}
</script>

<style scoped>
</style>
